<template>
    <fieldset v-if="address">
        <label v-if="label">{{ label }}</label>
        <div class="form-row">
            <div class="col-md-8 form-group">
                <label for="form-address2">{{$t('label.maps_street')}}</label>
                <input
                        type="text"
                        class="form-control"
                        id="form-address2"
                        v-bind:placeholder="$t('placeholder.maps_street')"
                        v-model="address.address"
                />
            </div>
            <div class="col-md-4 form-group">
                <label for="form-address-number">Numero</label>
                <input
                        type="text"
                        class="form-control"
                        id="form-address-number"
                        placeholder="Numero"
                        v-model="address.number"
                />
            </div>
            <div class="col-md-6 form-group">
                <label for="form-city">{{$t('label.maps_city')}}</label>
                <city-combo
                        v-model="address.city"
                        :cityName="address.city.city"
                        @city="value => address.city = value"
                />
            </div>

            <div class="col-md-6 form-group">
                <label for="form-provincia">Provincia</label>
                <input
                        type="text"
                        class="form-control"
                        id="form-provincia"
                        readonly="readonly"
                        v-model="address.city.province"
                />
            </div>
        </div>
        <div class="form-row">
            <div class="col-md-3 form-group">
                <label for="Latitudine">{{$t('label.maps_lat')}}</label>
                <input
                        type="text"
                        class="form-control"
                        id="lat"
                        v-bind:placeholder="$t('placeholder.maps_lat')"
                        v-model="address.lat"
                        v-validate="'required'"
                        name="latitudine"
                />
                <p class="text-danger" v-if="errors.has('latitudine')">{{ errors.first('latitudine') }}</p>
            </div>
            <div class="col-md-3 form-group">
                <label for="Longitudine">{{$t('label.maps_long')}}</label>
                <input
                        type="text"
                        class="form-control"
                        id="lng"
                        v-bind:placeholder="$t('placeholder.maps_long')"
                        v-model="address.lng"
                        v-validate="'required'"
                        name="longitudine"
                />
                <p class="text-danger" v-if="errors.has('longitudine')">{{ errors.first('longitudine') }}</p>
            </div>
            <div class="col-md-4" style="padding-top: 27px;">
                <button
                        id="getcoords-coords"
                        type="button"
                        class="btn btn-success w-100"
                        title="Ottieni le coordinate dall'indirizzo."
                        :disabled="isLoading"
                        @click="updateFromAddress"
                >Ottieni da indirizzo
                </button>
            </div>
            <div class="col-md-2" style="padding-top: 27px;">
                <button
                        id="getcoords-maps"
                        type="button"
                        class="btn btn-success w-100"
                        title="Visualizza la mappa"
                        @click="map.show = !map.show"
                >Mappa
                </button>
            </div>
        </div>
        <div class="form-row w-100" id="collapseMaps">
            <l-map
                    v-if="map.show"
                    :zoom="map.zoom"
                    :center="map.center"
                    :options="map.mapOptions"
                    style="height: 300px;width:100%"
            >
                <l-tile-layer :url="map.url" :attribution="map.attribution"/>
                <l-marker
                        :lat-lng="map.marker"
                        :draggable="true"
                        v-on:update:latLng="latlng => { address.lat = latlng.lat; address.lng=latlng.lng;}"
                ></l-marker>
            </l-map>
        </div>
    </fieldset>
</template>

<script>
    import axios from "axios";
    import {L, latLng, Icon} from "leaflet";

    delete Icon.Default.prototype._getIconUrl;
    Icon.Default.mergeOptions({
        iconRetinaUrl: require("leaflet/dist/images/marker-icon-2x.png"),
        iconUrl: require("leaflet/dist/images/marker-icon.png"),
        shadowUrl: require("leaflet/dist/images/marker-shadow.png")
    });
    import {LMap, LTileLayer, LMarker} from "vue2-leaflet";
    import CityCombo from "./CityCombo";

    export default {
        name: "AddressBox",
        components: {CityCombo, LMap, LTileLayer, LMarker},
        props: {
            value: {
                type: Object,
                default: () => {}
            },
            label: {
                type: String,
                default: null
            }
        },
        data() {
            return {
                isLoading: false,
                address: null,
                map: {
                    zoom: 13,
                    center: latLng(47.41322, -1.219482),
                    marker: latLng(47.41322, -1.219482),
                    url: "https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png",
                    attribution:
                        '&copy; <a href="http://osm.org/copyright">OpenStreetMap</a> contributors',
                    mapOptions: {
                        zoomSnap: 0.5
                    },
                    show: false
                }
            };
        },

        methods: {
            updateFromAddress: function () {
                this.isLoading = true;
                let self = this;
                axios({
                    method: "get",
                    url: "https://maps.googleapis.com/maps/api/geocode/json",
                    params: {
                        address:
                            self.address.address +
                            ", " +
                            (self.address.number || "") +
                            " " +
                            self.address.city.city,
                        key: process.env.VUE_APP_APY_KEY_GOOGLE
                    }
                })
                    .then(function (response) {
                        var results = response.data.results;
                        if (results.length > 0) {
                            self.address.lat = results[0].geometry.location.lat;
                            self.address.lng = results[0].geometry.location.lng;
                            self.map.center = latLng(self.address.lat, self.address.lng);
                            self.map.marker = latLng(self.address.lat, self.address.lng);
                        }
                    })
                    .catch(function (error) {
                        console.error(error);
                        var messaggeError = "";
                        console.log(error.response);
                        if (error.response && error.response.status == 400)
                            messaggeError = error.response.data.error_message;
                        else messaggeError = "Impossibile calcolare tutti i campi!!";

                        self.$notifyVue(
                            "top",
                            "center",
                            "warning",
                            messaggeError,
                            "ti-close"
                        );
                    })
                    .finally(function () {
                        self.isLoading = false;
                    });
            },
            updateValue: function () {
                this.address = Object.assign(
                    {
                        city: {
                            id: null,
                            city: "",
                            province: "",
                            region: "",
                            zip_code: "",
                            country: ""
                        },
                        address: "",
                        number: "",
                        lat: "",
                        lng: ""
                    },
                    this.value
                );

                this.map.center = latLng(this.address.lat, this.address.lng);
                this.map.marker = latLng(this.address.lat, this.address.lng);
            }
        },
        watch: {
            address: function (v2, v1) {
                this.$emit("input", this.address);
            }
        },
        created() {
            this.updateValue();
        }
    };
</script>
