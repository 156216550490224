<template>
  <transition name="modal">
    <div class="modal-mask">
      <div class="modal-wrapper">
        <div class="modal-container">
          <div class="modal-header">
            <h3>Seleziona immagine</h3>

            <button class="btn btn-default pull-left" @click="$emit('close')"> X </button> </div>

          <div class="modal-body" id="modal-body">
            <div class="row justify-content-md-center search">
            <div class="input-group mb-3">
  <input type="text" v-model="searchText" class="form-control" placeholder="Filtra" aria-label="Filtro" />

  <div class="input-group-append">
    <button class="btn btn-info" type="button" @click="searchFunc(1)" >Filtra</button>
  </div>
</div>

            </div>


 <div class="row justify-content-md-center">
              <div class="col-auto" v-for="(image, imageIndex) in images" :key="imageIndex">
                <div
                  :class="['image', selected==imageIndex? 'selected': '']"
                  :style="{ backgroundImage: `url('${image.url}')` }"
                  @click="selected = imageIndex"
                ></div>
                <small class="image-label">{{ image.title }}</small>
              </div>

            </div>

            <div class="row justify-content-md-center">
               <div class="class" v-if="loading"> <img src="@/assets/img/spinner.gif"> </div>
               <button v-if="hasPages && !loading" class="btn btn-default" @click="loadImages(page)">Carica altro</button>
            </div>

          </div>

          <div class="modal-footer">
            <slot name="footer">
              <button v-show="selected>=0" class="btn btn-success" @click="$emit('onimage', images[selected].url )">Seleziona</button>
              <button class="btn btn-default" @click="$emit('close')">Annulla</button>
            </slot>
          </div>
        </div>
      </div>
    </div>
  </transition>
</template>
<style lang="scss" scoped>
.image-label{
  display: block;
  max-width:  150px;
}
.image {
  background-size: cover;
  background-repeat: no-repeat;
  background-position: center;
  height: 120px;
  width: 150px;
  margin-bottom: 10px;
  cursor: pointer;

  &.selected {
    box-shadow: 0 0 0 4px #b9e00b;
  }
}
.search{
  margin-bottom: 1rem;
  margin-top: -1rem;
}

.modal-mask {
  position: fixed;
  z-index: 9998;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.5);
  display: table;
  transition: opacity 0.3s ease;
}

.modal-wrapper {
  display: table-cell;
  vertical-align: middle;
}

.modal-container {
  margin: 0px auto;
  padding: 20px 30px;
  background-color: #fff;
  border-radius: 2px;
  box-shadow: 0 2px 8px rgba(0, 0, 0, 0.33);
  transition: all 0.3s ease;
  width: 90%;
  max-height: 90%;
}

.modal-header, .modal-footer{
  padding: 1rem 0px;
}


.modal-body {
  margin: 20px 0;
  max-height: 400px;
  overflow-y: scroll;
}

/*
 * The following styles are auto-applied to elements with
 * transition="modal" when their visibility is toggled
 * by Vue.js.
 *
 * You can easily play with the modal transition by editing
 * these styles.
 */

.modal-enter {
  opacity: 0;
}

.modal-leave-active {
  opacity: 0;
}

.modal-enter .modal-container,
.modal-leave-active .modal-container {
  -webkit-transform: scale(1.1);
  transform: scale(1.1);
}

.button-new{
  &:active{
      border-style: solid;
      i{
        text-shadow: 1px 1px 1px darkgrey;
      }
  }

   background-color: #dee2e6;
    color: #66615b;
    width: 150px;
    height: 150px;

   i{
     font-size: 4rem;
    color: #ffffff9e;
    display: block;
    margin-bottom: 11px;
    text-shadow: 2px 2px 1px darkgrey;
  }

}
</style>
<script>
import axios from "axios";
import _ from "lodash";
const bodyScrollLock = require("body-scroll-lock");
const disableBodyScroll = bodyScrollLock.disableBodyScroll;
const enableBodyScroll = bodyScrollLock.enableBodyScroll;

export default {
  props: {},

  data: function() {
    return {
      images: [],
      selected: -1,
      page: 1,
      hasPages: false,
      loading: false,
      searchText: ""
    };
  },

  components: {},

  watch: {},

  methods: {
    searchFunc: function(){ this.loadImages(1); },
    loadImages: function(page) {
      let self = this;

      if (page == 1) {
        self.images = [];
      }
      self.loading = true;
      axios({
        method: "get",
        url: this.$url + "media",
        headers: this.$config.axiosHeaders(),
        params: {
          images: 1,
          page: page,
          limit: 20,
          searchText: self.searchText
        }
      })
        .then(function(response) {
          if (response.data.status == "success") {
            self.images = self.images.concat(
              _.chain(response.data.data)
                .filter(function(o) {
                  return (
                    (Array.isArray(o) && o.length > 0) || !Array.isArray(o)
                  );
                })
                .map(function(item) {
                  return {
                    title: item.name,
                    url: item.url,
                    thumb: item.url_thumb,
                    selected: false
                  };
                })
                .value()
            );

            self.page = response.data.meta.current_page + 1;
            self.hasPages =
              response.data.meta.last_page !== response.data.meta.current_page;
          }

          /*var color = 2;
          var icon = "ti-check";

          if (response.data.status == "success") {
            self.rows.splice(index, 1);
          } else {
            color = 3;
            icon = "ti-close";
          }
          self.$notifyVue(
            "top",
            "center",
            "success",
            response.data.data.message,
            icon
          );*/
          self.loading = false;
        })
        .catch(function(error) {
          self.loading = false;
          self.$processError(error);
          self.$notifyVue(
            "top",
            "center",
            "success",
            error.response.data.message,
            icon
          );
        });
    }
  },
  created: function() {
    this.loadImages(1);
    let el = document.querySelector("#modal-body");
    bodyScrollLock.disableBodyScroll(el);
  },
  destroyed: function() {
    bodyScrollLock.clearAllBodyScrollLocks();
  }
};
</script>
