<template>
  <div class="attachments-box p-4">             
      <draggable v-model="selectedImages" draggable=".item" @change="onChange" handle=".handle" v-if="selectedImages.length > 0">
        <transition-group type="transition" name="list-complete" >
          <div class="row item align-items-center my-2"
          v-for="(image, imageIndex) in selectedImages"
          :key="image.id"
        >
        <div class="col-auto"><i class="fa fa-align-justify handle" style="cursor: grab;"></i> </div>
<div class="col-auto">
<div
              :style="{ backgroundImage: 'url(' + image.url + ')' }"
              class="attachment-thumbnail"
            ></div>  
</div>
        <div class="col"> <h3 class="my-0 py-0"> {{ image.title }} </h3>

        <p-input v-bind:value="image.id" name="is_primary_image" type="radio" :checked="image.is_primary" @change="selezionaPredefinito(image, imageIndex, true)">
          Copertina </p-input>
        </div>
        <div class="col-auto">
          <span
              class="btn btn-primary btn-sm"
              title="Modifica Allegato"
              @click="show_dialog_image_in_component(image)"
            >
              <span class="fa fa-edit"></span>
            </span>
            <span></span>
            <span
              class="btn btn-danger btn-sm"
              title="Cancella Allegato"
              @click="removeItem(imageIndex)"
            >
              <span class="fa fa-trash"></span>
            </span>
        </div>
        
          </div>
        </transition-group>
      </draggable>          
    <div class="row">
      <div class="col-12 text-center">
        <input
          type="button"
          class="btn btn-primary"
          @click="show_dialog_image_in_component()"
          value="Aggiungi Immagine"
        />
      </div>
    </div>
  </div>
</template>
<script>
import Vue from "vue";
import ImagePicker from "./richeditor/ImagePicker";
import draggable from "vuedraggable";

export default {
  name: "AttachmentsBox",
  components: {
    draggable,
  },
  data() {
    return {
      selectedImages: [],     
    };
  },

  props: {
    value: {
      type: Array,
      default: () => [],
    },

    max: {
      type: Number,
      required: false,
      default: 10,
    },
  },

  computed: {},

  methods: {
    onChange(evt){
      if( evt.hasOwnProperty('moved')){
        this.selectedImages.map((element, index) => { element.order = index; } );
      }      
    },
    removeItem(idx) {
      var newPrimaryIdx = -1;      
      if( this.selectedImages[idx].is_primary ){                
          newPrimaryIdx = 0;
      }

      this.selectedImages.splice(idx, 1);


      if( newPrimaryIdx>= 0 && this.selectedImages.length > newPrimaryIdx ){
        this.selectedImages[newPrimaryIdx].is_primary = 1;
      }
    },
    selezionaPredefinito(image, index, checked) {      
       this.selectedImages.map((element, idx) => { 
          element.is_primary = (index == idx) && checked;          
      }); 
      this.notifySuperComponent();
    },
    notifySuperComponent: function () {
      this.$emit("input", this.selectedImages);
    },
    show_dialog_image_in_component: function (id) {
      let self = this;
      var ComponentClass = Vue.extend(ImagePicker);
      var instance = new ComponentClass({
        propsData: {
          max: this.max - self.selectedImages.length,
          filterObj: id || null,
        },
      });
      instance.$mount();
      instance.$on("close", function () {
        instance.$destroy();
        this.$el.parentNode.removeChild(this.$el);
      });
      instance.$on("onimage", function (images) {
        self.selectedImages = self.selectedImages.concat(images);        
        self.notifySuperComponent();
        instance.$destroy();
        this.$el.parentNode.removeChild(this.$el);
      });
      this.$el.appendChild(instance.$el);
    },
  },

  watch: {
    value: function () {
      this.selectedImages = this.value;      
      this.notifySuperComponent();
    },
  },

  created() {
    this.selectedImages = this.value;    
    this.notifySuperComponent();
  },
};
</script>

<style lang="scss" scoped>
.attachment-thumbnail {
  width: 100px;
  height: 100px;
  background-position: center;
  background-size: cover;
}

.v--modal-overlay {
  z-index: 9999;
}

.content_gallery {
  overflow-y: auto;
}

.info_box_media {
  border-left: 1px #d8d8d8 dashed;
  height: 500px;
  transition: 200ms linear;
}

.flip-list-move {
  transition: transform 0.5s;
}
.pretty .state label:before {
    border-color: #66615b;
}
.pretty.p-default input:checked ~ .state label:after {
    background-color: #212529 !important;
}

.pretty label{
  color: #66615b;
}
</style>
