<template>
  <div class="w-100">
        <date-picker
          inline
          :multiple="true"
          v-model="dates"
          @change="notify()"
          format="DD-MM-YYYY"
          :disabled-date="filterDates"
        />
        <div class="w-100 mt-2">
        <div class="btn-group">
  <button type="button" class="btn btn-info btn-sm dropdown-toggle" data-toggle="dropdown" aria-expanded="false">
    Elenco
  </button>
  <div class="dropdown-menu">
     <div class="dropdown-item border-bottom w-100 d-flex align-items-center justify-content-between" v-for="(date, i) in dates" :key="'dates' + i">
        <div class="">
          {{ $moment(date).format("D MMMM YYYY (dddd)") }}
        </div>
        <button
          type="button"
          class="ml-4 p-0 btn btn-link"
          title="Rimuovi"
          @click="
            dates.splice(i, 1);
            notify();
          "
        >
          <i class="fa fa-trash"></i>
        </button>
      </div>
  </div>
</div></div>
  </div>
</template>
<style lang="scss" scoped>
</style>
<script>
import DatePicker from "vue2-datepicker";
import "vue2-datepicker/index.css";
import "vue2-datepicker/locale/it";

export default {
  components: {
    DatePicker,
  },
  data() {
    return {
      dates: []
    };
  },
  props: ["value", "label", "disabledDates"],

  watch: {
    value(v2, v1) {
      this.parse(v2);
    },
  },
  computed: {},
  methods: {
    filterDates(date, currentValue) {
      return (
        this.disabledDates.findIndex(
          (item) => date.getTime() === item.getTime()
        ) > -1
      );
    },
    parse(v) {
      this.dates = [...(v || [])];
    },
    notify() {
      this.$emit("input", [...this.dates]);
      this.$nextTick(() => {
          this.$emit("change", {});
        });
    },
  },
  async mounted() {
    this.$moment.locale("it");
    this.parse(this.value);
  },
};
</script>
