<template>
  <div class="category-combo" >
    <Tree :value="options">
    <template slot-scope="{node, index, path, tree}">
      <div class="custom-control custom-checkbox form-check"> <input type="checkbox" :id="'node-' + node.id" class="custom-control-input form-check-input" v-model="categories" :value="node.id" @change="notify()"><label :for="'node-' + node.id" class="custom-control-label form-check-label">{{ node.label }}</label></div>
    </template>
  </Tree>
  </div>
</template>

<script>
import axios from "axios";
import {
  Tree, // Base tree
  Fold,
  Check,
  Draggable, // plugins
  foldAll,
  unfoldAll,
  cloneTreeData,
  walkTreeData,
  getPureTreeData, // utils
} from "he-tree-vue";
import "he-tree-vue/dist/he-tree-vue.css"; // base style
import Button from '../Inputs/Button.vue';


  export default {
  components: {
     Tree: Tree.mixPlugins([]),
   },
    Buttoname: "CategoryCombo",
  data() {
    return {
      categories: [],
      options: [],
      isLoading: true
    };
  },

  props: {
    mainCategory: {
      type: Number,
      default: -1
    },
    value: {
      type: Array,
      default: () => []
    }
  },

  watch: {
    value: function(v1, v2) {
      this.categories = this.value ? this.value.map( (itm) => {
        return itm.id || Number(itm);
      }) : [];
    },
    mainCategory: function() {
      this.loadMainCategory();
    }
  },

  methods: {
    notify: function() {
      this.$emit("input", this.categories);
      this.$emit("change", this.categories);
    },
    loadMainCategory() {
      //passo alle variabili data i contenuti ricevuti in input
      //recupero elenco target
      var self = this;
      self.isLoading = true;
      self.options = [];
      if( this.mainCategory < 0 ){
        self.isLoading = false;
        return;
      }

const catMapper = (items) => {
  return items? items.map(
            (item) =>{
              const obj = {
                "id" : item.id,
                "label": item.contents.it.title
              };

              if( item.children && item.children.length > 0){
                obj.children = catMapper( item.children );
              }

              return obj;
            }
          ): [];
}

      axios({
        method: "get",
        url:
          self.$url +
          "categories?main=" +
          this.mainCategory +
          "&lang=" +
          self.$config.getLanguage(),
        headers: this.$config.axiosHeaders()
      })
        .then(function(response) {
          var obj = response.data.data;
          self.$set(self, "options",catMapper(obj.children) );
        })
        .catch(function(error) {
          console.log(error);
          self.options = [];
        });
    }
  },

  created() {
    this.loadMainCategory();

    this.categories = this.value ? this.value.map( (itm) => {
        return itm.id || Number(itm);
    }): [];
  }
}; // export
</script>
<style lang="scss">
.category-combo{
  .he-tree {
  font-size: 1rem;
  .tree-node {
    background-color: white !important;
    border: none !important;
    margin-bottom: 0px !important;
    padding: 0px !important;
  }

   button{
     background: none;
    border: none;
    padding: 0;
    margin: 0;
    float: left;
    z-index: 20;
    display: block;
    transform: translate(-5px, 4px);
   }
  .has-children {
    button { display: unset; }
  }

  .no-children{
    button{display: none;}

    .custom-control{
      margin-left: 10px;
    }
  }

  .is-child{
    margin-left: 0px;
  }
  }
}
</style>
