<template>
  <div>
    <multiselect
      :value="selected"
      id="relate_destination_ert"
      :name="id"
      label="name"
      track-by="id"
      placeholder="Destinazioni"
      :options="options_data"
      :multiple="false"
      :searchable="true"
      :loading="isLoading"
      :internal-search="true"
      :clear-on-select="false"
      :close-on-select="true"
      :options_data-limit="300"
      :limit="3"
      :limit-text="limitText"
      :max-height="2000"
      :show-no-results="false"
      :hide-selected="false"
      @input="notify"
      @search-change="asyncFindString"
      selectLabel="Premi invio per selezionare"
    >
      <span slot="noResult">{{ $t("message.not_found_search") }}</span>
      <span slot="noOptions">Inizia a digitare il nome di una destinazioni</span>
    </multiselect>
  </div>
</template>
<script>
import Multiselect from "vue-multiselect";
import axios from "axios";
var LimitFunction = require("../../limitFunction").default;

export default {
  name: "DestinationCombo",
  components: { Multiselect },

  data() {
    return {
      id: null,
      selected: {},
      options_data: [],
      isLoading: false
    };
  },
  props: ["value"],
  watch: {
    value: function () {
      this.$set(this, "selected", this.value);
    },
  },
  methods: {
    limitText: function (count) {
      return `e ${count} altre destinazioni`;
    },
    notify: function (value) {
      this.$set(this, "selected", value ? {
        id: value.id,
        name: value.name,
      }: {});

      this.$emit("input", this.selected);
      this.$emit("comune", value? value.comune : null);
    },
    asyncFindString: function (itemToSearch) {
      if (itemToSearch && itemToSearch.length >= 2) {
        //servono almeno 3 caratteri per avviare la ricerca
        self = this;
        LimitFunction(function () {

          self.isLoading = true;

          axios({
            method: "post",
            url: self.$url + "destination/search",
            params: {
              keywords: itemToSearch,
            },
            headers: self.$config.axiosHeaders(),
          })
            .then(function (response) {
              self.$set(
                self,
                "options_data",
                _(response.data.data)
                  .map(function (item) {
                    return {
                      id: item.id,
                      name: item.contents.it.title,
                      comune: item.comune,
                    };
                  })
                  .value()
              );
              self.isLoading = false;
            })
            .catch(function (error) {
              self.isLoading = false;
            });
        }, 300);
      }
    },
  },
  created() {
    this.id = "destination-combo-" + Math.floor(Math.random());
    this.$set(this, "selected", this.value);
    if(this.value) {
      this.asyncFindString(this.value.name || "");
    }
  },
}; // export
</script>
