<template>
    <div class="form-group">
        <label for="select-publish-date">
            <i class="fa fa-calendar mr-2"></i> {{label}}
        </label>
        <date-picker
                id="select-publish-date"
                class="input form-control"
                autocomplete="off"
                v-model="options_data_selected"
                :config="datetime_options_1"
                @dp-change="this.notifySuperComponent"
        ></date-picker>
    </div>
</template>

<script>
    export default {
        name: "DateUpdate",

        data() {
            return {
                options_data_selected: '',
                isLoading: false,
                datetime_options_1: {
                    format: "DD/MM/YYYY HH:mm:ss",
                    useCurrent: false,
                    showClear: true,
                    showClose: true,
                    showTodayButton: true,
                    locale: "it",
                    widgetPositioning: {
                        horizontal: "auto",
                        vertical: "bottom"
                    },
                    tooltips: {
                        today: "Oggi",
                        clear: "Cancella",
                        close: "Chiudi",
                        selectMonth: "Seleziona Mese",
                        prevMonth: "Mese Precedente",
                        nextMonth: "Mese Successivo",
                        selectYear: "Seleziona Anno",
                        prevYear: "Anno Precedente",
                        nextYear: "Anno Successivo",
                    }
                }
            };
        },

        props: {
            value: {
                type: String,
                default: ''
            },
            label:{
                type:String,
                default: "Data Pubblicazione"
            }
        },

        watch: {
            value(v2,v1){
              this.options_data_selected = this.value ? this.$moment(this.value) : "";
            },
        },
        methods: {
            notifySuperComponent: function () {
                this.$emit("input", this.$moment(this.options_data_selected, "DD/MM/YYYY HH:mm:ss").format('YYYY-MM-DD HH:mm:ss') );
            },

            setStart(e) {
                this.$set(this.datetime_options_2, "minDate", e.date);
                this.notifySuperComponent();
            },
            setDate() {
                this.notifySuperComponent();
            }
        },
        created() {
            this.options_data_selected = ( this.value && this.value !== "Invalid date" ) ? this.$moment(this.value) : "";
        }
    };
</script>
