<template>
    <div>
        <multiselect
                v-model="selected"
                label="city"
                track-by="id"
                placeholder="Comune"
                :options="options_data"
                selectLabel="Invio per selezionare"
                :multiple="false"
                :searchable="true"
                :loading="isLoading"
                :internal-search="true"
                :clear-on-select="true"
                :close-on-select="true"
                :options_data-limit="3"
                :limit="10"
                :limit-text="limitText"
                :max-height="2000"
                :show-no-results="false"
                :hide-selected="false"
                :preserve-search="true"
                @input="notify"
                @search-change="searchByString"
        >
            <span slot="noResult">{{ $t("message.not_found_search") }}</span>
            <span slot="noOptions">Non ci sono risultati disponibili</span>
        </multiselect>
    </div>
</template>
<script>
    import Multiselect from "vue-multiselect";
    import axios from "axios";

    var LimitFunction = require("../../limitFunction").default;


    export default {
        components: {Multiselect},
        data() {
            return {
                selected: null,
                selected_id: -1,
                options_data: [],
                isLoading: false
            };
        },
        props: ["value", "cityName", "provincia"],
        watch: {
            value: function (v2, v1) {
                if (v2 !== this.selected) {
                    this.updateCombo();
                    this.selected = v2;
                }
            },
            cityName: function (v2, v1) {
                this.selectByCityName();
            },
            provincia: function (v2, v1) {
                this.updateCombo();
            }
        },
        methods: {
            apiCall: function (params, callback) {
                self = this;
                LimitFunction(function () {
                    self.isLoading = true;
                    axios({
                        method: "get",
                        url: self.$url + "operations/comuni",
                        params: params,
                        headers: self.$config.axiosHeaders()
                    })
                        .then(function (response) {
                            callback(response.data.data, params);
                            self.isLoading = false;
                        })
                        .catch(function (error) {
                            self.isLoading = false;
                            console.log(error);
                        });
                }, 300);
            },
            updateCombo: function () {
                this.selected = this.value;
                let self = this;
                if (this.selected > 0) {
                    this.apiCall({id: this.selected}, function (data, params) {
                        self.populateComboBox(data, params);
                        self.selected = self.options_data[0];
                    });
                    return;
                }
            },
            notify: function (value, id) {                
                    this.selected_id = this.selected ? this.selected.id : -1;
                    this.$emit("input", this.selected);
                    this.$emit("city", this.selected);
            },
            limitText: function (count) {
                return `e ${count} altre destinazioni`;
            },

            populateComboBox: function (elements, params) {
                this.$set(this, "options_data", elements);
            },
            selectByCityName: function () {
                let params = {};
                let self = this;

                if (self.cityName && self.cityName.length >= 2) {
                    params.name = self.cityName;

                    if (self.provincia > 0) {
                        params.provincia = self.provincia;
                    }

                    this.apiCall(params, function (data, params) {
                        self.populateComboBox(data, params);

                        if (self.options_data.length == 1) {
                            self.selected = self.options_data[0];
                            self.selected_id = self.selected.id;
                        } else {
                            self.selected = null;
                            self.selected = _(self.options_data).find(function (o) {
                                return o.city == self.cityName;
                            });
                            if (self.selected !== null) {
                                self.selected_id = self.selected.id;
                            }
                        }
                        self.notify();
                    });
                }
            },
            searchByString: function (itemToSearch) {
                //servono almeno 3 caratteri per avviare la ricerca
                let params = {};
                let self = this;

                if (itemToSearch && itemToSearch.length >= 2) {
                    params.name = itemToSearch;

                    if (self.provincia > 0) {
                        params.provincia = self.provincia;
                    }

                    this.apiCall(params, function (data, params) {
                        self.populateComboBox(data, params);
                    });
                }
            }
        },
        created() {          
            if(this.cityName!='undefined'){  
                this.updateCombo();
                this.selectByCityName();
            }
        }
    }; // export
</script>
