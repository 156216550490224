var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"row",staticStyle:{"margin-top":"-13px"}},[_c('div',{staticClass:"col-12"},[_c('upload-box',{attrs:{"collectionType":"downloads"},on:{"uploaded":_vm.updateUpload},scopedSlots:_vm._u([{key:"info",fn:function(){return [_c('div',{staticClass:"dropzone-custom-content"},[_c('h3',{staticClass:"dropzone-custom-title"},[_vm._v(" Trascina qui un file per caricarlo ")]),_c('div',{staticClass:"subtitle"},[_vm._v(" ...oppure fai click per caricarne uno dal tuo computer ")])]),_c('p',{staticClass:"mt-2",staticStyle:{"color":"grey"}},[_vm._v(_vm._s(_vm.messageFileAccepted))])]},proxy:true}])})],1),_c('div',{staticClass:"col-12"},[(_vm.options_data_selected.length > 0)?_c('table',{staticClass:"table table-striped table-hover table-responsive-sm"},[_vm._m(0),_c('tbody',_vm._l((_vm.options_data_selected),function(image,imageIndex){return _c('tr',{key:imageIndex},[_c('td',[_vm._v(" "+_vm._s(image.id)+" ")]),(image.cover && image.cover.thumb_url)?_c('td',[_c('div',{staticClass:"attachment-thumbnail",style:({
                backgroundImage:
                  'url(' +
                  ((image.cover ? image.cover.thumb_url : '') || '') +
                  ')',
              })}),_c('button',{staticClass:"copertina",attrs:{"type":"button"},on:{"click":function($event){return _vm.show_dialog_image_in_component(image.cover, imageIndex)}}},[_vm._v(" Sostituisci la copertina ")])]):_c('td',[_c('button',{staticClass:"copertina",attrs:{"type":"button"},on:{"click":function($event){return _vm.show_dialog_image_in_component(image.cover, imageIndex)}}},[_vm._v(" Seleziona la copertina ")])]),_c('td',{staticClass:"text-left"},[_vm._v(_vm._s(image.name))]),_c('td',[_c('related-languages',{model:{value:(image.language),callback:function ($$v) {_vm.$set(image, "language", $$v)},expression:"image.language"}})],1),_c('td',[_c('input',{staticClass:"btn btn-danger btn-sm",attrs:{"type":"button","value":"RIMUOVI"},on:{"click":function($event){return _vm.removeItem(imageIndex)}}})]),_c('td')])}),0)]):_vm._e()])])
}
var staticRenderFns = [function (){var _vm=this,_c=_vm._self._c;return _c('thead',[_c('tr',[_c('th',{attrs:{"width":"20px"}},[_vm._v("ID")]),_c('th',{attrs:{"width":"120px"}},[_vm._v("Copertina")]),_c('th',[_vm._v("File")]),_c('th',[_vm._v("Lingua")]),_c('th')])])
}]

export { render, staticRenderFns }