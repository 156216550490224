<template>
  <div>

    <multiselect
          id="ajax"
          v-model="selected"
          label="name"
          track-by="id"
          placeholder="IAT"
          :options="options_data"
          selectLabel="Invio per selezionare"
          :multiple="true"
          :searchable="true"
          :loading="isLoading"
          :internal-search="true"
          :clear-on-select="true"
          :close-on-select="true"
          :options_data-limit="3"
          :limit="10"
          :limit-text="limitText"
          :max-height="2000"
          :show-no-results="false"
          :hide-selected="false"
          :preserve-search="true"
          @input="notify"
          @search-change="asyncFindString"
    >
      <span slot="noResult">{{ $t("message.not_found_search") }}</span>
      <span slot="noOptions">Inizia a digitare il nome di uno IAT</span>
    </multiselect>
  </div>
</template>
<script>
  import Multiselect from "vue-multiselect";
  import axios from "axios";
  var LimitFunction = require("../../limitFunction").default;

  export default {
    name: "IATCombo",
    components: { Multiselect },

    data() {
      return {
        id: null,
        selected: {},
        options_data: [],
        isLoading: false,
      };
    },
    props: ["value"],
    watch: {
      value: function() {
        this.$set(this, "selected", this.value);
      }
    },
    methods: {
      limitText: function(count) {
        return `e ${count} altre destinazioni`;
      },
      notify: function(value) {
        this.$emit("input", this.selected );
        // this.$emit("comune", 'comune assente' );
      },
      asyncFindString: function(itemToSearch) {
        if (itemToSearch && itemToSearch.length > 2) {
          let self = this;
          LimitFunction(function() {
            self.isLoading = true;

            axios({
              method: "post",
              url: self.$url + "iat/search",
              params: {
                keywords: itemToSearch
              },
              headers: self.$config.axiosHeaders()
            })
                    .then(function(response) {
                      self.$set(self, "options_data", response.data.data);
                      self.isLoading = false;
                    })
                    .catch(function(error) {
                      self.isLoading = false;
                    });
          }, 300);
        }
      }
    },
    mounted() {
      this.id = "iat-combo-" + Math.floor(Math.random());
      this.$set(this, "selected", this.value);
      this.$set(this, "options_data", this.value);
    }
  }; // export
</script>
