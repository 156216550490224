<template>
  <div class="w-100">
    <div class="row no-gutters">
      <div class="col-6">
        <div class="form-group pb-2">
          <label>Data inizio</label>

          <div class="w-100">
            <date-picker
              v-model="start"
              format="DD-MM-YYYY"
              @change="onChange()"
            ></date-picker>
          </div>
        </div>
      </div>
      <div class="col-6">
        <div class="form-group pb-2">
          <label>Data fine</label>
          <div class="w-100">
            <date-picker
              v-model="end"
              format="DD-MM-YYYY"
              @change="onChange()"
            ></date-picker>
          </div>
        </div>
      </div>
    </div>

    <div class="row no-gutters">
      <div class="col-6">
        <div class="form-group pb-2">
          <label>Date aggiuntive</label>
          <div class="small mb-2" role="alert">
            Aggiungi altre date in cui si svolge l'evento
          </div>
          <date-array
            v-model="includes"
            :disabledDates="excludes"
            @change="onChange()"
          >
          </date-array>
        </div>
      </div>
      <div class="col-6">
        <div class="form-group pb-2">
          <label>Date escluse</label>
          <div class="small mb-2" role="alert">
            Aggiungi delle date in cui non si svolge l'evento
          </div>
          <date-array
            v-model="excludes"
            :disabledDates="includes"
            @change="onChange()"
          >
          </date-array>
        </div>
      </div>
    </div>

    <div class="form-group">
      <label>Giorni della settimana</label>
      <div class="small mb-2" role="alert">
        Indica in quali giorni della settimana si tiene l'evento
      </div>

      <weekdays-selector v-model="weekdays" @change="onChange()">
      </weekdays-selector>
    </div>
  </div>
</template>
<style lang="scss" scoped>
</style>
<script>
import DatePicker from "vue2-datepicker";
import "vue2-datepicker/index.css";
import "vue2-datepicker/locale/it";

import WeekdaysSelector from "@/components/altrama/WeekdaysSelector";
import DateArrayInline from "@/components/altrama/DateArrayInline";

export default {
  components: {
    DatePicker,
    DateArray: DateArrayInline,
    WeekdaysSelector,
  },
  data() {
    return {
      start: new Date(),
      end: new Date(),
      weekdays: [0, 0, 0, 0, 0, 0, 0],
      includes: [],
      excludes: [],
    };
  },
  props: ["value"],

  watch: {
    value(v2, v1) {
      this.parse(v2);
    },
  },
  computed: {},
  methods: {
    parse(v) {
      this.start = this.$moment(v.start || new Date()).toDate();
      this.end = this.$moment(v.end || new Date()).toDate();

      this.includes = (v.includes || []).map((date) => {
        return this.$moment(date).toDate() ?? this.$moment(new Date()).toDate();
      });

      this.excludes = (v.excludes || []).map((date) => {
        return this.$moment(date).toDate() ?? this.$moment(new Date()).toDate();
      });

      this.weekdays = [...(v.weekdays || [])];
      if (this.weekdays.length != 7) {
        this.weekdays = [0, 0, 0, 0, 0, 0, 0];
      }
    },
    getObject() {
      return {
        includes: this.includes.map((item) => {
          return this.$moment(item).format("YYYY-MM-DD");
        }),
        excludes: this.excludes.map((item) => {
          return this.$moment(item).format("YYYY-MM-DD");
        }),
        start: this.$moment(this.start).format("YYYY-MM-DD"),
        end: this.$moment(this.end).format("YYYY-MM-DD"),
        weekdays: this.weekdays,
      };
    },
    notify() {
      this.$emit("input", this.getObject());
    },

    onChange() {
      this.$nextTick(() => {
        this.notify();
      });
    },
  },
  async mounted() {
    this.parse(this.value);
  },
};
</script>
