<template>
    <div class="form-group">
        <label for="select-status">{{ categoryName }}</label>
        <select
                v-model="selected"
                @change="notifySuperComponent()"
                name="Category"
                class="custom-select"
        >            
            <option
                    v-for="(item, i) in options_data"
                    :value="i"
                    :key="item.id"
            >{{item.name}}
            </option>
        </select>
    </div>
</template>

<script>
    export default {
        name: "DestinationBox",

        data() {
            return {
                options_data: [],
                selected: null,
            };
        },
        props: {
            categoryName: {
                type: String,
                default: ''
            },
            value: {
                type: Object,
                default: () => ({'id': -1})
            }
        },

        watch: {
            value: function () {
                this.selected = this.options_data.findIndex((o) => o.id == this.value.id);
            }
        },

        methods: {
            notifySuperComponent: function () {
                this.$emit("input", this.options_data[this.selected]);
                this.$emit("change", this.selected)
            },

            async asyncRelatedStatuses() {
                let self = this;

                self.options_data = [
                    {
                        id: -1,
                        name: 'Tutte',
                    },
                    {
                        id: 2001,
                        name: 'Emilia',
                    },
                    {
                        id: 2002,
                        name: 'Romagna',
                    },
                    {
                        id: 2003,
                        name: 'Bologna-Modena',
                    },
                ];
            },
        },

        async created() {
            await this.asyncRelatedStatuses();
            let self = this;
            let selectedID = self.value ? self.value.id : -1;
            this.selected = this.options_data.findIndex((o) => o.id == selectedID);
        }
    }; // export
</script>
