<template>
  <div class="row" style="margin-top: -13px">
    <div class="col-12">
      <upload-box @uploaded="updateUpload" collectionType="downloads">
        <template v-slot:info>
          <div class="dropzone-custom-content">
            <h3 class="dropzone-custom-title">
              Trascina qui un file per caricarlo
            </h3>
            <div class="subtitle">
              ...oppure fai click per caricarne uno dal tuo computer
            </div>
          </div>
          <p class="mt-2" style="color: grey">{{ messageFileAccepted }}</p>
        </template>
      </upload-box>
    </div>
    <div class="col-12">
      <table
        v-if="options_data_selected.length > 0"
        class="table table-striped table-hover table-responsive-sm"
      >
        <thead>
          <tr>
            <th width="20px">ID</th>
            <th width="120px">Copertina</th>
            <th>File</th>
            <th>Lingua</th>
            <th></th>
          </tr>
        </thead>
        <tbody>
          <tr
            v-for="(image, imageIndex) in options_data_selected"
            :key="imageIndex"
          >
            <td>
              {{ image.id }}
            </td>
            <td v-if="image.cover && image.cover.thumb_url">
              <div
                :style="{
                  backgroundImage:
                    'url(' +
                    ((image.cover ? image.cover.thumb_url : '') || '') +
                    ')',
                }"
                class="attachment-thumbnail"
              ></div>
              <button
                type="button"
                class="copertina"
                @click="show_dialog_image_in_component(image.cover, imageIndex)"
              >
                Sostituisci la copertina
              </button>
            </td>
            <td v-else>
              <button
                type="button"
                class="copertina"
                @click="show_dialog_image_in_component(image.cover, imageIndex)"
              >
                Seleziona la copertina
              </button>
            </td>

            <td class="text-left">{{ image.name }}</td>
            <!-- nome file -->
            <td>
              <related-languages v-model="image.language"></related-languages>
            </td>
            <!-- lingua -->
            <td>
              <!--
                        <button
                                class="btn btn-danger btn-sm"
                                title="Cancella Allegato"
                                @click="removeItem(imageIndex)"
                        >
                            <span class="fa fa-trash">  </span> Rimuovi
                            </button>
                            -->

              <input
                type="button"
                class="btn btn-danger btn-sm"
                value="RIMUOVI"
                @click="removeItem(imageIndex)"
              />
            </td>
            <td></td>
            <!-- cambio copertina -->
          </tr>
        </tbody>
      </table>
    </div>
  </div>
</template>
<script>
import axios from "axios";
import Vue from "vue";
import ImagePicker from "./../altrama/richeditor/ImagePicker";
import RelatedLanguages from "../altrama/RelatedLanguages";
import UploadBox from "../../components/altrama/UploadPDFBox";

export default {
  components: {
    ImagePicker,
    RelatedLanguages,
    UploadBox,
  },
  data() {
    return {
      options_data_selected: [],
      id_predefinito: -1,
    };
  },
  props: {
    value: {
      type: Array,
      default: () => [],
    },
    max: {
      type: Number,
      required: false,
      default: 100,
    },
    fileExtensionAccepted: {
      type: String,
      required: false,
      default: ".pdf",
    },
    messageFileAccepted: {
      type: String,
      required: false,
      default: "",
    },
  },

  computed: {},

  methods: {
    updateUpload: function (data) {
      data.cover = {
        id: "",
        thumb_url: "",
        caption: "",
        description: "",
        copyright: "",
      };
      this.options_data_selected.push(Object.assign({}, data));
      this.notifySuperComponent();
    },
    updateDateLastMod(object) {
      this.dataPubblicazione = object;
      if (this.entity.pub_date != this.dataPubblicazione)
        this.modificaEntity = true;
    },
    removeItem(idx) {
      if (this.id_predefinito == this.options_data_selected[idx].id) {
        this.id_predefinito =
          this.options_data_selected.length > 0
            ? this.options_data_selected[0].id
            : -1;
      }
      this.options_data_selected.splice(idx, 1);
      this.notifySuperComponent();
    },
    notifySuperComponent: function () {
      this.$emit("input", this.options_data_selected);
    },
    show_dialog_image_in_component: function (file) {
      let self = this;
      var ComponentClass = Vue.extend(ImagePicker);
      var instance = new ComponentClass({
        propsData: {
          max: 1 /*, filterObj: id.cover.id ? { id: id.cover.id  } : null */,
        },
      });
      instance.$mount();
      instance.$on("close", function () {
        instance.$destroy();
        this.$el.parentNode.removeChild(this.$el);
      });
      instance.$on("onimage", function (images) {
        let image = images[0];
        file.id = image.id;
        file.thumb_url = image.url;
        file.caption = image.title;
        file.description = image.description;
        file.copyright = image.copyright;
        instance.$destroy();
        this.$el.parentNode.removeChild(this.$el);
        self.notifySuperComponent();
      });
      this.$el.appendChild(instance.$el);
    },
  },

  watch: {
    value: function () {
      this.options_data_selected = this.value.map((item) => {
        if (item.cover === null) {
          item.cover = Object.assign(
            {},
            {
              id: "",
              thumb_url: "",
              caption: "",
              description: "",
              copyright: "",
            }
          );
        }
        return item;
      });
    },
  },

  created() {
    this.options_data_selected = this.value.map((item) => {
      if (item.cover === null) {
        item.cover = Object.assign(
          {},
          {
            id: "",
            thumb_url: "",
            caption: "",
            description: "",
            copyright: "",
          }
        );
      }

      return item;
    });
  },
};
</script>

<style lang="scss" scoped>
.attachment-thumbnail {
  width: 100px;
  height: 100px;
  background-position: center;
  background-size: cover;
  display: inline-block;
}

.v--modal-overlay {
  z-index: 9999;
}

.content_gallery {
  overflow-y: auto;
}

.info_box_media {
  border-left: 1px #d8d8d8 dashed;
  height: 500px;
  transition: 200ms linear;
}

table thead tr th,
table th {
  border-top: 0px;
}
table td {
  text-align: center;
}

button.copertina {
  background: none;
  border: none;
  color: #17a2b8;
  font-weight: bold;
}
</style>
