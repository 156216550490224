<template>
        <select name="combobox" class="custom-select" @change="change($event)">
            <option :selected="value==item.id" v-for="item in values" v-bind:key="item.id" :value="item.id">{{ item.name }}</option>
        </select>

</template>
<script>
export default {
  data: function() {
            return {
                selectedItem: this.value
            };
       },
       props: ['labels', 'values', 'name', "value"],
       methods: {
            change: function(event) {
                this.$emit('input', event.target.value);
            }
       }
};
</script>
