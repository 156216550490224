<template>
  <div>
    <loader :show="isLoading" :width="40" :height="40"></loader>
    <draggable
      tag="ul"
      :list="contacts"
      class="list-group-flush p-0"
      handle=".handle"
      @end="onMoveCallback"
    >
      <li class="list-group-item " v-for="(element, idx) in contacts" :key="'contact-' + idx">
         <div class="row no-gutters">
              <div class="col-auto p-2 d-flex align-items-center">
                  <i class="fa fa-align-justify handle " style="cursor: move;"></i>
              </div>
              <div class="my-2 col-md-3 col-sm-12">
                <div class="input-group my-0">
                  <div class="input-group-prepend">
                      <span class="input-group-text" id="basic-addon1"><i :class="getIcon(element.type)"></i></span>
                  </div>
                  <input type="text" class="form-control" placeholder="Inserisci Etichetta" v-model="element.label" @change="$emit('input', contacts )" />
                </div>
              </div>
              <div class="my-2 col-md col-sm-12">
                <input type="text" class="mx-0 form-control" placeholder="Inserisci valore" v-model="element.value" @change="$emit('input', contacts )" />
              </div>

              <div class="col-auto p-2 d-flex align-items-center">
                <button class="btn btn-link" @click="removeAt(idx); $emit('input', contacts )"> <i class="fa fa-trash trash"></i> </button>
              </div>
            </div>
      </li>
      <div slot="footer">

        <div class="row list-group- collapse collapsed" id="addContact">
          <div class="list-group-item- py-2 col">
            <a
              class="btn btn-info dropdown-item text-center"
              @click="addContact('email')"
              data-toggle="collapse"
              href="#addContact"
            >
              <i class="fa fa-envelope mr-2" title="aggiungi email"></i>Email
            </a>
          </div>
          <div class="list-group-item py-2 col">
            <a
              class="btn btn-info dropdown-item text-center"
              @click="addContact('website')"
              data-toggle="collapse"
              href="#addContact"
            >
              <i class="fa fa-link mr-2" title="aggiungi sito web"></i>Sito Web
            </a>
          </div>
          <div class="list-group-item py-2 col">
            <a
              class="btn btn-info dropdown-item text-center"
              @click="addContact('telephone')"
              data-toggle="collapse"
              href="#addContact"
            >
              <i class="fa fa-phone mr-2" title="aggiungi telefono"></i>Telefono
            </a>
          </div>
          <div class="list-group-item- py-2 col">
            <a
              class="btn btn-info dropdown-item text-center"
              @click="addContact('fax')"
              data-toggle="collapse"
              href="#addContact"
            >
              <i class="fa fa-fax mr-2" title="aggiungi fax"></i>Fax
            </a>
          </div>
        </div>
        <div class="col-12 text-center">
        <button
          type="button"
          class="btn btn-primary"
          data-toggle="collapse"
          href="#addContact"
          role="button"
          aria-expanded="false"
          aria-controls="addContact"
        >
        Nuovo Contatto
        </button>
        </div>
      </div>
    </draggable>
  </div>
</template>
<style scoped>
.list-group-item {
  padding-left: 0px;
  padding-right: 0px;
}
</style>
<script>
import draggable from "vuedraggable";
import _ from "lodash";

export default {
  components: {
    draggable
  },
  watch: {
    value: function(v2, v1) {
      this.$set(this, "contacts", _.sortBy(this.value, ["order"]));
    }
  },
  methods: {
    getIcon: function(item) {
      switch (item) {
        case "email":
          return "fa fa-envelope mr-2";
        case "website":
          return "fa fa-link mr-2";
        case "fax":
          return "fa fa-fax mr-2";
        case "telephone":
          return "fa fa-phone mr-2";
      }
    },
    addContact: function(type) {
      this.contacts.push({
        id: null,
        order: this.contacts.length + 1,
        label: type,
        type: type,
        value: type == 'telephone' ? '+ 39 ' : "",
        lang: this.lang
      });
    },
    removeAt: function(idx) {
      if (confirm("Confermi la rimozione dell'elemento?")) {
        this.contacts.splice(idx, 1);
      }
    },
    onMoveCallback: function(evt, originalEvent) {
      for (var i in this.contacts) {
        this.contacts[i].order = Number(i) + 1;
      }
    }
  },
  props: {
    value: {
      type: Array,
      default: () => []
    },
    lang: {
      type: String,
      default: () => "it"
    }
  },
  mounted() {
    this.$set(this, "contacts", _.sortBy(this.value, ["order"]));
    this.isLoading = false;
  },
  data() {
    return {
      isLoading: true,
      contacts: []
    };
  }
};
</script>
