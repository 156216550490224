<template>
  <div>

    <div class="separator mb-2" v-if="addendums.length > 0">
      CONTENUTI AGGIUNTIVI
    </div>

    <loader :show="isLoading" :width="40" :height="40"></loader>

    <draggable
      tag="div"
      :list="addendums"
      handle=".handle"
      @start="onMoveStart"
      @end="onMoveCallback"
    >

      <addendum-card
        v-for="(element, idx) in addendums"
        :key="'addendum-' + idx"
        :collapse="true"
        :title="element.title"
        v-on:input="onTitleChange(element, $event)"
      >
          <div class="row">
            <div class="col">
              <rich-editor
                  v-model="element.content"
                  :useScript="false"
                  :useAlignment="false"
                  :useIndent="false"
                  :useTable="false"
                  :useWordCount="false"
                  v-on:input="onContentChange"
                />
            </div>
          </div>

          <div class="row">
            <div class="col d-flex align-items-center mt-2">

              <span
                class="btn btn-danger btn-sm ml-auto"
                title="Cancella Contenuto"
                @click="removeAt(idx); $emit('input', addendums )"
              >
                Delete&nbsp;
                <span class="fa fa-trash"></span>
              </span>

            </div>
          </div>
      </addendum-card>
    </draggable>

    <div class="separator my-3">
      <button
        class="btn btn-sm p-2"
        style="line-height: normal !important;"
        @click.prevent="addAddendum()"
      >
        <i class="ti ti-plus" ></i>&nbsp;
        Nuovo Contenuto Aggiuntivo
      </button>
    </div>

  </div>
</template>
<style scoped>
.separator {
  display: flex;
  align-items: center;
  text-align: center;
}

.separator::before,
.separator::after {
  content: '';
  flex: 1;
  border-bottom: 3px dashed #ccc;
}

.separator:not(:empty)::before {
  margin-right: 1em;
}

.separator:not(:empty)::after {
  margin-left: 1em;
}
</style>
<script>
import draggable from "vuedraggable";
import _ from "lodash";
import AddendumCard from '@/components/Cards/AddendumCard';
import RichEditor from "@/components/altrama/RichEditor";

export default {
  components: {
    draggable,
    AddendumCard,
    RichEditor
  },
  watch: {
    value: function(v2, v1) {
      this.$set(this, "addendums", _.sortBy(this.value, ["order"]));
    }
  },
  methods: {
    addAddendum: function() {
      this.addendums.push({
        id: null,
        order: this.addendums.length + 1,
        title: '',
        content: '',
        lang: this.lang
      });
    },
    removeAt: function(idx) {
      if (confirm("Confermi la rimozione dell'elemento?")) {
        this.addendums.splice(idx, 1);
      }
    },
    onMoveStart: function(evt, originalEvent) {
      // console.log('onMoveStart');
      this.isDragging = true;
    },
    onMoveCallback: function(evt, originalEvent) {
      // console.log('onMoveCallback');
      for (var i in this.addendums) {
        this.addendums[i].order = Number(i) + 1;
      }
      // this.$set(this, "addendums", this.addendums);
      this.$emit('input', this.addendums);
      this.isDragging = false;
    },
    onTitleChange: function(element, title) {
      // console.log('onTitleChange', this.addendums, element, title);
      if(!this.isDragging) {
        element.title = title;
        this.$emit('input', this.addendums );
      }
    },
    onContentChange: function(evt) {
      // console.log('onContentChange', this.addendums);
      if(!this.isDragging) {
        this.$emit('input', this.addendums );
      }
    }
  },
  props: {
    value: {
      type: Array,
      default: () => []
    },
    lang: {
      type: String,
      default: () => "it"
    },
  },
  mounted() {
    this.$set(this, "addendums", _.sortBy(this.value, ["order"]));
    this.isLoading = false;
  },
  data() {
    return {
      isLoading: true,
      isDragging : false,
      addendums: []
    };
  }
};
</script>
