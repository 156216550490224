<template>
  <div>
    <div class="form-row mb-2">
      <div class="col">
      <select class="custom-select" v-model="times.type" @change="notify">
        <option value="" selected disabled> Seleziona tipologia </option>
        <option value="timerange" > Intervallo </option>
        <option value="freetext"> Orario libero </option>
        <option value="program"> Rimanda al programma </option>
        <option value="website"> Rimanda al sito </option>
      </select>
      </div>
    </div>
    <div class="form-row">
      <div class="col-6">
    <table class="table table-bordered" v-if="times.type=='timerange'" >
      <tr>
      <td class="align-middle" >Mattina</td>
      <td class="align-middle"><vue-timepicker @change="notify" format="HH:mm" close-on-complete v-model="times.morning.start"> </vue-timepicker> </td>
      <td class="align-middle"><vue-timepicker @change="notify" format="HH:mm" close-on-complete v-model="times.morning.end"> </vue-timepicker> </td>
   </tr>
     <tr>
      <td class="align-middle">Pomeriggio</td>
      <td class="align-middle"><vue-timepicker @change="notify" format="HH:mm" close-on-complete v-model="times.afternoon.start"> </vue-timepicker> </td>
      <td class="align-middle"><vue-timepicker @change="notify" format="HH:mm" close-on-complete v-model="times.afternoon.end"> </vue-timepicker> </td>
    </tr>
     <tr>
      <td class="align-middle">Sera</td>
      <td class="align-middle"><vue-timepicker @change="notify" format="HH:mm" close-on-complete v-model="times.evening.start"> </vue-timepicker> </td>
      <td class="align-middle"><vue-timepicker @change="notify" format="HH:mm" close-on-complete v-model="times.evening.end"> </vue-timepicker> </td>
    </tr>
    </table>
    </div>
    </div>

    <div v-if="times.type=='freetext'" class="form-row">
      <div class="col">
              <textarea
                @change="notify"
                width="100%"
                rows="6"
                cols="50"
                class="input form-control"
                maxlength="300"
                aria-required="false"
                aria-invalid="false"
                v-model="times.freetext[lang]"
              />
              <span
                class="pull-right d-inline-block small m-2"
                id="count_message"
              >{{ 300 - times.freetext[lang].length }} caratteri disponibili.</span>
      </div>
    </div>

  </div>
</template>
<script>
import VueTimepicker from "vue2-timepicker";
import "vue2-timepicker/dist/VueTimepicker.css";
import _ from "lodash";

export default {
  components: {
    VueTimepicker
  },
  data() {
    return {
      times : {
            type: "",
            morning : {
              start : "",
              end: ""
            },
            afternoon : {
              start : "",
              end: ""
            },
            evening : {
              start : "",
              end: ""
            },
            freetext: this.$contentTemplate("")
        }
    };
  },
  props: [ "value", "lang" ],

  watch: {
    value(v2, v1) {
      if( v2 !== v1 ){
          this.parse();
      }
    },
    lang(v2, v1) {
      this.$set(this.times.freetext, v2, this.times.freetext[v2] || "" );
    },

  },
  methods: {
    notify() {
      this.$emit("input", this.times);
    },
   parse(){
      this.times = Object.assign({}, {
            type: "",
            morning : {
              start : "",
              end: ""
            },
            afternoon : {
              start : "",
              end: ""
            },
            evening : {
              start : "",
              end: ""
            },
            freetext: this.$contentTemplate("")
        }, this.value);
   }
  },
  mounted() {
    this.parse();
  }
};
</script>
