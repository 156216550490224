<template>
  <div class="form-group">
    <label for="select-status">{{ categoryName }}</label>
    <loader :show="isLoading" :width="40" :height="40"></loader>

    <select
      v-if="!isLoading"
      v-model="selected"
      @change="notifySuperComponent()"
      name="Category"
      class="custom-select"
    >
      <slot name="options">
        <option :value="-1" disabled>Seleziona un valore</option>

        <option v-for="(item, i) in options_data" :value="i" :key="item.id">
          {{ item.name }}
        </option>
      </slot>
    </select>
  </div>
</template>

<script>
import axios from "axios";
export default {
  name: "CategoryBox",

  data() {
    return {
      options_data: [],
      selected: { id: -1 },
      isLoading: true,
    };
  },
  props: ["mainCategory", "categoryName", "value"],
  watch: {
    value: function () {
      this.findSelected();
    },
  },

  methods: {
    notifySuperComponent: function () {
      this.$emit("input", this.options_data[this.selected]);
      this.$emit("change", this.selected);
    },
    findSelected() {

      const singleValue = Array.isArray(this.value) ? this.value[0] : this.value;
      const findingValue = ( singleValue.id || Number(singleValue) ) || -1 ;

      this.selected = this.options_data.findIndex(
        (o) =>{
          console.info( Number(o.id),  findingValue, Number(o.id) ===  Number(findingValue) );
          return o.id === findingValue; }
      );
    },
    async loadMainCategory() {
      let self = this;
      self.isLoading = true;

      return axios({
        method: "GET",
        url: self.$url + "operations/categories",
        params: {
          main: this.mainCategory,
          lang: self.$config.getLanguage(),
        },
        headers: this.$config.axiosHeaders(),
      })
        .then(function (response) {
          self.options_data = [...response.data.data];
          self.findSelected();
        })
        .catch(function (error) {
          self.$processError(error);
          self.options_data = [];
        })
        .finally(() => (self.isLoading = false));
    },
  },
  mounted: async function () {
    this.loadMainCategory();
  },
}; // export
</script>
